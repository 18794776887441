.ProjectPreview{
    object-fit: inherit;
    width: inherit;
    height: inherit;
    position: relative;
}

.ProjectPreview img, .ProjectPreview .project-preview-video-thumbnail{
    object-fit: inherit;
    width: inherit;
    height: inherit;
}

.ProjectPreview .transparent-layer{
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: #fff0;
    top: 0;
    left: 0;
}

.ProjectPreview .transparent-layer:hover{
    cursor: pointer;
}

.ProjectPreview .video-play-icon-wrapper{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProjectPreview .video-play-icon-wrapper:hover{
    cursor: pointer;
}
