.ProjectPreviewPopUp{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.ProjectPreviewPopUp .pop-up-img:hover,
.ProjectPreviewPopUp video:hover,
.ProjectPreviewPopUp .VideoPlayer:hover{
    opacity: initial;
    transform: none;
    transition-duration: unset;
}

.ProjectPreviewPopUp .black-background{
    position: fixed;
    width: 150vw;
    height: 100%;
    background-color: #0008;
}

.ProjectPreviewPopUp video, .ProjectPreviewPopUp img, .ProjectPreviewPopUp .VideoPlayer{
    transition: none;
    position: relative;
    top: 10vh;
    object-fit: contain;
    max-width: 70vw;
    max-height: 70vh;
    z-index: 1;
}