.Project{
    overflow-x: hidden;
    position: relative;
}

.Project h1{
    text-align: center;
    margin-bottom: 10px;
}

.Project .gallery{
    padding: 0 20%;
    display: grid;
    grid-template-columns: repeat(3, 20vw);
    column-gap: 2%;
    transform: translateX(-1.5vw);
}

.Project .gallery .ProjectPreview{
    width: 20vw;
    height: 20vw;
    margin-bottom: 10px;
    object-fit: cover;
}

h2{
    text-align: center;
}

.Project .ProjectPreview .project-preview-thumbnail,
.Project .ProjectPreview .VideoPlayer{
    border-width: 0;
    border-radius: 0.5em;
    transition: all 0.2s ease-in-out;
}

.Project .ProjectPreview .project-preview-thumbnail:hover,
.Project .ProjectPreview .VideoPlayer:hover{
    cursor: pointer;
    opacity: 0.7;
    transform: scale(0.95);
    transition-duration: 0.2s;
}

.Project .ProjectPreview .ProjectPreviewPopUp{
    object-fit: contain;
}

.Project-Footer{
    margin: 10em 20%;
}

.Project-Footer h1{
    margin: 2em 0;
    font-size: 30px;
}

.Project-Footer .ProjectTypeGallery{
    margin: 0;
    grid-template-columns: repeat(4, 15vw);
    column-gap: 5%;
}

.Project-Footer .ProjectTypeGallery .WorkPreview{
    width: 15vw;
    height: 15vw;
}


/*----------------- media query for .gallery -----------*/
@media (max-width: 768px){
    .Project .gallery,
    .Project-Footer .ProjectTypeGallery{
        grid-template-columns: repeat(2, 45%);
        transform: none;
        column-gap: 7%;
    }

    .Project .gallery .ProjectPreview,
    .Project-Footer .ProjectTypeGallery .WorkPreview{
        align-self: center;
        width: 28vw;
        height: 28vw;
    }

    .Project-Footer .ProjectTypeGallery .WorkPreview h2{
        font-size: 20px;
    }
}


@media (max-width: 426px){
    .Project .gallery,
    .Project-Footer .ProjectTypeGallery{
        grid-template-columns: repeat(1, 100%);
        transform: none;
    }

    .Project .gallery .ProjectPreview,
    .Project-Footer .ProjectTypeGallery .WorkPreview{
        align-self: center;
        width: 16.5em;
        height: 16.5em;
    }

    .Project-Footer .ProjectTypeGallery{
        grid-template-columns: repeat(1, 100%);
    }

    .Project-Footer .ProjectTypeGallery .WorkPreview{
        align-self: center;
        width: 16.5em;
        height: 16.5em;
    }
}




/* ------------------------------------------------------- */