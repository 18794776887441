*{
  font-family: sans-serif;
  font-weight: 100;
  list-style-type: none;
  text-decoration: none;
  padding: 0;
  margin: 0;

  --theme-color-white: #F9FAF4;
  --theme-color-black: #222627;
  --theme-color-orange: #ffbe91;
  --theme-color-yellow: #fcf6bd;
  --theme-color-red: #c10848;
  color: white;
}

input:focus-visible, textarea:focus-visible{
  outline: none;
}

.background-image{
  height:100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  /*background-color: var(--theme-color-black);*/
  background: rgba(255, 255, 255, 0.8) url("assets/HomePreviewImages/background.png") no-repeat fixed;
  background-size: cover;
  z-index: -10;
  filter: brightness(53%);
}

.background-image .brightness{
  width: 100vw;
  height: 100vh;
}
